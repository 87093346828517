import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PortfolioProjects from '../components/portfolio-section';

export const query = graphql`
	{
		allStrapiProjects(sort: { fields: updated_at, order: DESC }) {
			nodes {
				id
				Category
				Description
				FeaturedProject
				Name
				WebsiteURL
				updated_at
				Image {
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`;

const AboutPage = ({ data }) => {
	const { allStrapiProjects: { nodes: projects } } = data;

	return (
		<Layout>
			<SEO keywords={[ `Website Development`, `Website Design`, `Web Application`, `Data Analytics`, `Data Visualization`, `Freelance`, `Colin Willer` ]} title="About" />
			<PortfolioProjects projects={projects} />
		</Layout>
	);
};

export default AboutPage;
