import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';

const PortfolioProjects = ({ projects }) => {
	return (
		<section className="mb-12 pb-12">
			<h2 className="font-bold text-center text-wtblue my-2 text-2xl lg:text-3xl xl:text-4xl xl:my-4">
				Portfolio Projects
			</h2>
			<p className="mx-auto text-gray-900 my-2 text-center text-sm md:w-2/3 md:text-md lg:text-lg xl:mt-4">
				See what types of projects I've worked on to learn more about how Willer Technologies can help propel
				your business to the next level
			</p>

			{/* <!-- Container --> */}
			<div className="mx-auto px-4 mb-12">
				{/* <!-- Grid wrapper --> */}
				<div className="-mx-4 flex flex-wrap justify-center">
					{projects.map((project, index) => {
						return (
							<div key={index} className="w-full flex flex-col p-4 max-w-md sm:w-1/2 md:w-full lg:w-1/3">
								{/* <!-- Column contents --> */}
								<div className="flex flex-col flex-1 pb-8 bg-white rounded-md shadow-lg">
									{project.Image && (
										<Image
											fluid={project.Image.childImageSharp.fluid}
											className="w-full mb-4 z-0 rounded-t-md"
										/>
									)}
									<div className="flex-1 px-6">
										<h3 className="text-base text-wtblue uppercase text-center font-semibold lg:text-left">
											{project.Category}
										</h3>
										<h4 className="text-gray-900 text-xl font-bold leading-snug text-center lg:text-left">
											{project.Name}
										</h4>
										<p className="mt-2 text-sm text-center text-gray-900 md:text-left lg:text-sm xl:text-base">
											{project.Description}
										</p>
									</div>
									{project.WebsiteURL && (
										<a
											href={project.WebsiteURL}
											target="_blank"
											rel="noreferrer"
											className="mt-6 mx-16 inline-flex items-center justify-center px-6 py-2 text-wtblue font-semibold bg-white border border-wtblue rounded hover:bg-wtbluehover hover:text-white xl:mx-32"
										>
											Visit Website
										</a>
									)}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
};

PortfolioProjects.propTypes = {
	projects: PropTypes.array.isRequired
};

export default PortfolioProjects;
